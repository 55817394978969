<template>
  <div class="bg-white py-2 rounded-xl" id="HSSKOverview">
    <div v-if="hasHSSKOverview">
      <div class="w-full px-3 h-full">
        <HeaderHSSKOverview />
      </div>
      <div class="flex mt-4 cs-height-container">
        <MenuOverView />

        <div class="flex justify-center overflow-x-scroll mx-auto">
          <div class="h-detail w-full px-3 relative">
            <div class="cs-container fs-16">
              <HealthRiskFactors v-if="tab === 'riskProblem'" />
              <CurrentHealthProblems v-show="tab === 'currentProblem'" />
              <div
                v-if="tab === 'master'"
                class="text-secondary fs-30 font-bold text-center"
              >Đang cập nhật</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex align-items-center justify-content-center py-5">
      <p class="fs-16 fw-500 text-center">Chức này không khả dụng với Workspace hiện tại!</p>
    </div>
  </div>
</template>

  <script>
import { IconSearch } from '@/components/Icon'
import { ButtonHoDo } from '@/components/Button'
import MenuOverView, { MENU_HSSK } from './MenuHSSKOverview.vue'
import { mapState } from 'vuex'
import HeaderHSSKOverview from './HeaderHSSKOverview.vue'
import HealthRiskFactors from './HealthRiskFactors.vue'
import CurrentHealthProblems from './CurrentHealthProblems.vue'
import { APP_MODE } from '../../utils/constants'

export default {
  name: 'TreeView',
  components: {
    MenuOverView,
    IconSearch,
    ButtonHoDo,
    HeaderHSSKOverview,
    HealthRiskFactors,
    CurrentHealthProblems
  },
  data () {
    return {
      searchValue: '',
      tab: 'master',
      extraSidebar: true,
      medicalRecordMain: null,
      idMedicalRecords: null,
      medical_record_code: null,
      treat_doctor: null,
      mr_id: null
    }
  },

  computed: {
    ...mapState({
      personStore: state => state.person
    }),
    hasHSSKOverview () {
      return (
        (this.$featureList && this.$featureList['hsskOverview']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['hsskOverview']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['hsskOverview']
            : false) ||
        false
      )
    }
  },
  watch: {
    '$route.query.tab' () {
      this.handleSelected()
      this.showTab = false
      setTimeout(() => {
        this.showTab = true
      }, 100)
    }
  },
  mounted () {
    this.handleSelected()

    this.mr_id = this.$route.params.id
  },
  methods: {
    handleSelected () {
      if (
        this.$route.query.tab &&
        MENU_HSSK.findIndex(item => item.index === this.$route.query.tab) !== -1
      ) {
        this.tab = this.$route.query.tab
      } else {
        this.tab = 'master'
        this.$router.replace({ query: { tab: 'master' } })
      }
    },
    getDetail (show) {
      this.tab = show
      if (this.tab !== this.$route.query.tab) {
        this.$router.replace({ query: { tab: this.tab } })
      }
    },
    backListBADT () {
      this.$router.go(-1)
    },
    onOpenSidebar () {
      this.extraSidebar = !this.extraSidebar
    },
    async getDetailBADT () {}
  }
}
</script>

  <style lang="scss" scoped>
.h-detail {
  // height: calc(100vh - 14rem);
  height: "100%";
}

.cs-container {
  width: 1000px !important;
}

.cs-height-container {
  height: calc(100vh - 200px);
}
</style>
