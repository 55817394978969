<template>
  <div class="header-electronic py-3 text-black relative">
    <div class="w-full">
      <span class="text-xl font-bold" v-if="person">
        <span>{{person.name}}</span> -
        <span>{{person.record_name}}</span>
      </span>

      <div class="row mt-2 font-semibold">
        <div class="col-md-3">
          <div>{{`${person.gender}(${person.age} tuổi)`}}</div>
          <div v-if="person.address">
            <span class="font-normal">{{person.address}}</span>
          </div>
        </div>
        <div class="col-md-3">
          SĐT:
          <span class="font-normal mx-2">{{person.phone}}</span>
        </div>
        <div class="col-md-3">
          CCCD:
          <span class="font-normal mx-2">{{person.cmnd}}</span>
        </div>
        <div class="col-md-3">
          BHYT:
          <span class="font-normal mx-2">{{person.insurance_number}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
const MOCK_DATA = {
  name: 'Nguyễn Minh Quang',
  address: 'Xóm 5, Tiên La, Đoan Hùng, Hưng Hà, Thái Bình',
  phone: '0971395430',
  cmnd: '025346356823983',
  insurance_number: '9827356742342345',
  record_name: 'Hồ sơ sức khoẻ tổng quát của Quang',
  gender: 'Nam',
  age: 23
}

export default {
  name: 'HeaderHSSKOverview',
  data () {
    return {
      person: { ...MOCK_DATA }
    }
  },
  mounted () {},
  computed: {},
  methods: {}
}
</script>
  
  