<template>
  <div class="text-black w-full">
    <div class="w-full">
      <h2
        class="fs-30 text-center"
        style="
          padding-top: 9pt;
          padding-left: 26pt;
          text-indent: -21pt;
          text-align: left;
        "
      >
        Vấn đề sức khỏe, bệnh tật hiện tại
      </h2>

      <div class="mt-4">
        <div>
          <div>
            <h4 class="font-bold fs-18 text-left">3.1. Khám lâm sàng</h4>

            <table
              class="w-full"
              style="border-collapse: collapse"
              cellspacing="0"
            >
              <tr style="height: 32pt">
                <td class="cs-border" style="width: 200pt">
                  <span class="ml-4" style="text-indent: 0pt; text-align: left"
                    >Ngày khảo sát</span
                  >
                  <span class="ml-20" style="text-indent: 0pt; text-align: left"
                    >/</span
                  >
                  <span class="ml-20" style="text-indent: 0pt; text-align: left"
                    >/</span
                  >
                </td>
                <td class="cs-border" style="width: 200pt">
                  <span class="ml-4" style="text-indent: 0pt; text-align: left"
                    >Bác sĩ khám:</span
                  >
                </td>
              </tr>
            </table>

            <p class="fs-18 my-4">Dấu hiệu sinh tồn, nhân trắc học</p>

            <table
              class="w-full"
              style="border-collapse: collapse"
              cellspacing="0"
            >
              <tr style="height: 32pt">
                <td class="cs-border cs-header" style="width: 128pt">
                  <p class="text-center font-bold text-white">
                    Mạch (lần/ phút)
                  </p>
                </td>
                <td class="cs-border cs-header" style="width: 128pt">
                  <p class="text-center font-bold text-white">Nhiệt độ (*C)</p>
                </td>
                <td class="cs-border cs-header" style="width: 128pt">
                  <p class="text-center font-bold text-white">
                    Huyết áp (mmHg)
                  </p>
                </td>
                <td class="cs-border cs-header" style="width: 128pt">
                  <p class="text-center font-bold text-white">
                    Nhịp thở (lần/ phút)
                  </p>
                </td>
              </tr>
              <tr style="height: 57pt">
                <td class="cs-border" style="width: 121pt">
                  <p style="text-indent: 0pt; text-align: left">
                    <br />
                  </p>
                </td>
                <td class="cs-border" style="width: 121pt">
                  <p style="text-indent: 0pt; text-align: left">
                    <br />
                  </p>
                </td>
                <td class="cs-border" style="width: 121pt">
                  <div class="flex flex-col gap-4 p-2">
                    <div>(P):</div>
                    <div>(T):</div>
                  </div>
                </td>
                <td class="cs-border" style="width: 121pt">
                  <p style="text-indent: 0pt; text-align: left">
                    <br />
                  </p>
                </td>
              </tr>
            </table>
            <p style="text-indent: 0pt; text-align: left">
              <br />
            </p>
            <table
              class="w-full"
              style="border-collapse: collapse"
              cellspacing="0"
            >
              <tr style="height: 32pt">
                <td class="cs-border cs-header" style="width: 128pt">
                  <p class="text-center font-bold text-white">Chiều cao(cm)</p>
                </td>
                <td class="cs-border cs-header" style="width: 128pt">
                  <p class="text-center font-bold text-white">Cân nặng(kg)</p>
                </td>
                <td class="cs-border cs-header" style="width: 128pt">
                  <p class="text-center font-bold text-white">Vòng bụng(cm)</p>
                </td>
                <td class="cs-border cs-header" style="width: 128pt">
                  <p class="text-center font-bold text-white">BMI(kg/m2)</p>
                </td>
              </tr>
              <tr style="height: 32pt">
                <td class="cs-border" style="width: 121pt">
                  <p style="text-indent: 0pt; text-align: left">
                    <br />
                  </p>
                </td>
                <td class="cs-border" style="width: 121pt">
                  <p style="text-indent: 0pt; text-align: left">
                    <br />
                  </p>
                </td>
                <td class="cs-border" style="width: 121pt">
                  <p style="text-indent: 0pt; text-align: left">
                    <br />
                  </p>
                </td>
                <td class="cs-border" style="width: 121pt">
                  <p style="text-indent: 0pt; text-align: left">
                    <br />
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <h4 class="font-bold fs-18 text-left mt-4">
              3.2. Kiểm tra sức khỏe
            </h4>

            <div>
              <div>
                <h4 class="font-bold fs-18 text-left ml-4">
                  3.2.1. Da, niêm mạc, lông tóc móng:
                </h4>
                <table
                  class="w-full"
                  style="border-collapse: collapse"
                  cellspacing="0"
                >
                  <tr style="height: 30pt">
                    <td
                      rowspan="2"
                      class="cs-border cs-header"
                      style="width: 128pt"
                    >
                      <p class="text-center font-bold text-white">Da</p>
                    </td>
                    <td class="cs-border" style="width: 397pt" colspan="5">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 30pt">
                    <td class="cs-border" style="width: 91pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Ngứa da</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 83pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Phát ban</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 78pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Mày đay</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 72pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Mụn nhọt</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 73pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Loét</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 30pt">
                    <td class="cs-border cs-header" style="width: 87pt">
                      <p class="text-center font-bold text-white">Màu sắc da</p>
                    </td>
                    <td class="cs-border" style="width: 91pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Bình thường</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 83pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nhợt</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 150pt" colspan="2">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Vết bầm tím</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 73pt">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 30pt">
                    <td class="cs-border cs-header" style="width: 87pt">
                      <p class="text-center font-bold text-white">Niêm mạc</p>
                    </td>
                    <td class="cs-border" style="width: 397pt" colspan="5">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 30pt">
                    <td class="cs-border cs-header" style="width: 87pt">
                      <p class="text-center font-bold text-white">
                        Màu niêm mạc
                      </p>
                    </td>

                    <td class="cs-border" style="width: 91pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Bình thường</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 83pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nhợt</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 78pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Xuất huyết</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 72pt">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                    <td class="cs-border" style="width: 73pt">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 31pt">
                    <td
                      rowspan="2"
                      class="cs-border cs-header"
                      style="width: 87pt"
                    >
                      <div class="py-4 text-center font-bold text-white">
                        Lông, tóc
                      </div>
                    </td>

                    <td class="cs-border" style="width: 397pt" colspan="5">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 30pt">
                    <td class="cs-border" style="width: 91pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Rậm</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 83pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Thưa</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 150pt" colspan="2">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Màu sắc bất thường</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 73pt">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 30pt">
                    <td
                      rowspan="2"
                      class="cs-border cs-header"
                      style="width: 87pt"
                    >
                      <div class="py-4 text-center font-bold text-white">
                        Móng
                      </div>
                    </td>
                    <td class="cs-border" style="width: 397pt" colspan="5">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 30pt">
                    <td class="cs-border" style="width: 91pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Dày</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 83pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Khum</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 78pt">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                    <td class="cs-border" style="width: 72pt">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                    <td class="cs-border" style="width: 73pt">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 37pt">
                    <td class="cs-border" style="width: 87pt" bgcolor="#616B80">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                    <td class="cs-border px-2" style="width: 397pt" colspan="5">
                      <p class="content-none mt-4"></p>
                    </td>
                  </tr>
                  <tr style="height: 37pt">
                    <td
                      class="cs-border cs-header"
                      style="width: 87pt"
                      bgcolor="#616B80"
                    >
                      <div class="py-4 text-center font-bold text-white">
                        Mô tả
                      </div>
                    </td>
                    <td class="cs-border" style="width: 397pt" colspan="5">
                      <div class="flex flex-col gap-4 p-4 px-2">
                        <div class="content-none"></div>
                        <div class="content-none"></div>
                        <div class="content-none"></div>
                        <div class="content-none"></div>
                        <div class="content-none"></div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="mt-4">
                <h4 class="font-bold fs-18 text-left ml-4">
                  3.2.2. Đầu, mặt, cổ
                </h4>

                <table
                  style="border-collapse: collapse"
                  class="w-full"
                  cellspacing="0"
                >
                  <tr style="height: 32pt">
                    <td
                      class="cs-border cs-header"
                      style="width: 52pt"
                      rowspan="4"
                    >
                      <div
                        class="text-white h-full flex items-center justify-center"
                      >
                        Mắt
                      </div>
                    </td>
                    <td class="cs-border" style="width: 432pt" colspan="4">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 115pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nhìn mờ, nhìn đôi</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 101pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nhức mỏi</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 216pt" colspan="2">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chảy nước mắt nhiều</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 115pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đỏ</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 101pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Ngứa</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 100pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nhìn gần kém</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 116pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nhìn xa kém</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 115pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Sụp mi</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 101pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Ruồi bay</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 216pt" colspan="2">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Mất thị trường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td
                      class="cs-border cs-header"
                      style="width: 52pt"
                      rowspan="3"
                    >
                      <div
                        class="text-white h-full flex items-center justify-center"
                      >
                        Tai
                      </div>
                    </td>
                    <td class="cs-border" style="width: 432pt" colspan="4">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 115pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đau</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 101pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nghe kém</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 100pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Ù tai</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 116pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nhiều ráy tai</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 115pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chảy mủ tai</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 317pt" colspan="3">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Dùng máy trợ thính</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td
                      class="cs-border cs-header"
                      style="width: 52pt"
                      rowspan="4"
                    >
                      <div
                        class="text-white h-full flex items-center justify-center text-center"
                      >
                        Răng miệng
                      </div>
                    </td>
                    <td class="cs-border" style="width: 432pt" colspan="4">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 115pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Hơi thở hôi</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 101pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đau răng</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 100pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Cao răng</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 116pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Răng ê buốt</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 115pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chảy máu lợi</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 101pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Viêm lợi</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 100pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Niềng răng</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 116pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đeo răng giả</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 432pt" colspan="4">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Bất thường khớp thái dương hàm</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td
                      class="cs-border cs-header"
                      style="width: 52pt"
                      rowspan="3"
                    >
                      <div
                        class="text-white h-full flex items-center justify-center"
                      >
                        Tổng quát
                      </div>
                    </td>
                    <td class="cs-border" style="width: 115pt">
                      <div
                        class="flex items-center justify-center fs-17 font-bold"
                      >
                        Tuyến nước bọt
                      </div>
                    </td>
                    <td class="cs-border" style="width: 101pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Sưng viêm</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 100pt">
                      <div
                        class="flex items-center justify-center fs-17 font-bold"
                      >
                        Tuyến giáp
                      </div>
                    </td>
                    <td class="cs-border" style="width: 116pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Tăng kích thước</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 216pt" colspan="2">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Tĩnh mạch cổ nổi</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 216pt" colspan="2">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đau nhức vùng cổ</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 216pt" colspan="2">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>U, cục vùng đầu mặt</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 216pt" colspan="2">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nhức đầu</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 122pt">
                    <td class="cs-border cs-header" style="width: 52pt">
                      <div
                        class="text-white h-full flex items-center justify-center"
                      >
                        Mô tả
                      </div>
                    </td>
                    <td class="cs-border" style="width: 432pt" colspan="4">
                      <div class="flex flex-col p-4 px-2 gap-4">
                        <div class="content-none" />
                        <div class="content-none" />
                        <div class="content-none" />
                        <div class="content-none" />
                        <div class="content-none" />
                        <div class="content-none" />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="mt-4">
                <h4 class="font-bold fs-18 text-left ml-4">
                  3.2.3. Ngực, lưng, bụng:
                </h4>

                <table
                  class="w-full"
                  style="border-collapse: collapse"
                  cellspacing="0"
                >
                  <tr style="height: 32pt">
                    <td
                      class="cs-border cs-header"
                      style="width: 80pt"
                      rowspan="5"
                    >
                      <div
                        class="text-white h-full flex items-center justify-center"
                      >
                        Ngực, lưng
                      </div>
                    </td>
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Biến dạng lồng ngực</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Gù vẹo</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Điểm đau</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đau khi hít thở</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nhịp thở bất thường</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Tiếng thổi bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 47pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Động tác hô hấp bất thường</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Rì rào phế nang tăng</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Rì rào phế nang giảm</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 62pt">
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex flex-col gap-4 px-2 py-4">
                        <div class="s14 fs-16">Mô tả thêm:</div>
                        <div class="content-none" />
                        <div class="content-none" />
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td
                      class="cs-border cs-header"
                      style="width: 80pt"
                      rowspan="6"
                    >
                      <div
                        class="text-white h-full flex items-center justify-center"
                      >
                        Bụng
                      </div>
                    </td>
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đau bụng</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Nôn, buồn nôn</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đi ngoài ra máu</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 47pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Vết mổ thành bụng</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Mất cân đối hình dạng</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>U cục</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 47pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Trương lực thành bụng yếu</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Trương lực thành bụng co cứng</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Gan to</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Lách to</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Lỗ bẹn nông yếu</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 62pt">
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex flex-col gap-4 px-2 py-4">
                        <div class="s14 fs-16">Mô tả thêm:</div>
                        <div class="content-none" />
                        <div class="content-none" />
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td
                      class="cs-border"
                      style="width: 80pt"
                      rowspan="7"
                      bgcolor="#616B80"
                    >
                      <div
                        class="text-white h-full flex items-center justify-center fs-17"
                      >
                        Sinh dục, tiết niệu
                      </div>
                    </td>
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Tiểu buốt</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Tiểu rắt</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Tiểu nhiều</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="font-bold text-center">Nam</div>
                    </td>
                  </tr>
                  <tr style="height: 47pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Xuất tinh sớm</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Rối loạn cương dương</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Xuất tinh ra máu</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="text-center font-bold">Nữ</div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Khô rát âm đạo</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Khí hư</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Sa sinh dục</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 24pt">
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex flex-col gap-4 px-2 py-4">
                        <div class="s14 fs-16">Mô tả thêm:</div>
                        <div class="content-none" />
                        <div class="content-none" />
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td
                      class="cs-border cs-header"
                      style="width: 80pt"
                      rowspan="6"
                    >
                      <div
                        class="text-white h-full flex items-center justify-center"
                      >
                        Cơ xương khớp
                      </div>
                    </td>
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 260pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đau</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex flex-col gap-2 justify-center">
                        <img
                          class="object-center object-contain"
                          src="../../assets/images/img-musculoskeletal.png"
                        />

                        <div class="text-center">Mặt trước</div>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex flex-col gap-2 justify-center">
                        <img
                          class="object-center object-contain"
                          src="../../assets/images/img-musculoskeletal-2.png"
                        />

                        <div class="text-center">Mặt sau</div>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Dáng đi bất thường</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 268pt" colspan="2">
                      <p
                        class="s14"
                        style="
                          padding-top: 8pt;
                          padding-left: 8pt;
                          text-indent: 0pt;
                          text-align: left;
                        "
                      >
                        Vị trí:
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Sưng khớp</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 268pt" colspan="2">
                      <p
                        class="s14"
                        style="
                          padding-top: 8pt;
                          padding-left: 8pt;
                          text-indent: 0pt;
                          text-align: left;
                        "
                      >
                        Vị trí:
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Trật khớp</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 268pt" colspan="2">
                      <p
                        class="s14"
                        style="
                          padding-top: 8pt;
                          padding-left: 8pt;
                          text-indent: 0pt;
                          text-align: left;
                        "
                      >
                        Vị trí:
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 62pt">
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex flex-col gap-4 px-2 py-4">
                        <div class="s14 fs-16">Mô tả thêm:</div>
                        <div class="content-none" />
                        <div class="content-none" />
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td
                      class="cs-border cs-header"
                      style="width: 80pt"
                      rowspan="6"
                    >
                      <div
                        class="text-white h-full flex items-center justify-center"
                      >
                        Tâm thần kinh
                      </div>
                    </td>
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chưa phát hiện bất thường</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Stress</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Lo âu</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Mất ngủ</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Đau đầu</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Hoa mắt</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Chóng mặt</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 47pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Mất thăng bằng</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Tăng phản xạ gân xương</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Giảm phản xạ gân xương</span>
                      </div>
                    </td>
                  </tr>
                  <tr style="height: 32pt">
                    <td class="cs-border" style="width: 136pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Giảm cơ lực</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 133pt">
                      <div class="flex items-center gap-2 ml-2">
                        <input type="checkbox" :disabled="true" />
                        <span>Tê bì</span>
                      </div>
                    </td>
                    <td class="cs-border" style="width: 135pt">
                      <p style="text-indent: 0pt; text-align: left">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr style="height: 60pt">
                    <td class="cs-border" style="width: 404pt" colspan="3">
                      <div class="flex flex-col gap-4 px-2 py-4">
                        <div class="s14 fs-16">Mô tả thêm:</div>
                        <div class="content-none" />
                        <div class="content-none" />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrentHealthProblems'
}
</script>

<style lang="scss" scoped>
.cs-border {
  border-top-style: solid;
  border-top-width: 2pt;
  border-top-color: #dadada;
  border-left-style: solid;
  border-left-width: 2pt;
  border-left-color: #dadada;
  border-bottom-style: solid;
  border-bottom-width: 2pt;
  border-bottom-color: #dadada;
  border-right-style: solid;
  border-right-width: 2pt;
  border-right-color: #dadada;
}

.cs-header {
  background-color: #616b80;
  font-size: 16px;

  p {
    margin-bottom: 0 !important;
  }
}

.content-none {
  width: 100%;
  border-bottom: 2px dotted;
}

.cs-img {
  object-fit: contain;
  object-position: center;
}
</style>