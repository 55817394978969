<template>
  <div class="h-full">
    <div @click="(isCollapse = !isCollapse)" v-if="false">
      <svg
        class="cursor-pointer select-none -mt-1"
        :class="!isCollapse ? 'fliped' : ''"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9985 6.00001L7.99959 6.16709L8.02529 8.16693L21.0242 7.99985L20.9985 6.00001ZM21.0628 10.9996L11.0636 11.1281L11.0893 13.128L21.0885 12.9994L21.0628 10.9996ZM21.1527 17.999L21.127 15.9992L8.12811 16.1663L8.15381 18.1661L21.1527 17.999ZM3.03098 8.64115L6.65682 12.1848L3.12325 15.8206L4.55126 17.2123L9.48659 12.1485L4.42274 7.21315L3.03098 8.64115Z"
          fill="black"
        />
      </svg>
    </div>
    <el-menu
      :default-active="indexActive"
      class="el-menu-vertical-demo"
      @select="handleOpen"
      :collapse="isCollapse"
    >
      <el-menu-item v-for="menuItem in MENU_HSSK" :key="menuItem.index" :index="menuItem.index">
        <span slot="title">{{ menuItem.label }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>


<script>
export const MENU_HSSK = [
  {
    label: 'Hành chính',
    index: 'master'
  },
  {
    label: 'Lịch sử hình thành sức khoẻ bệnh tật',
    index: 'riskProblem'
  },
  {
    label: 'Vấn đề sức khoẻ, bệnh tật hiện tại',
    index: 'currentProblem'
  }
]

export default {
  name: 'MenuOverview',
  props: [],
  data () {
    return {
      isCollapse: false,
      current_route_name: '',
      MENU_HSSK
    }
  },
  mounted () {
    this.current_route_name = this.$route.query.tab
  },
  watch: {
    '$route.query.tab' (value) {
      this.current_route_name = value
    }
  },
  computed: {
    indexActive () {
      return this.MENU_HSSK.find(item => item.index === this.current_route_name)
        ?.index
    }
  },
  methods: {
    handleOpen (key) {
      if (key !== this.$route.query.tab) {
        this.$router.replace({ query: { tab: key } })
      }
    },
    isRouteNames (routeNames = []) {
      return routeNames.includes(this.current_route_name)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 280px;
    height: 100%;
  }
  .el-menu--collapse {
    width: 48px;
  }
  .el-menu-item,
  .el-submenu__title,
  .el-submenu .el-menu-item {
    height: fit-content;
    line-height: 32px;
    padding: 0 10px !important;
    white-space: pre-wrap;
  }

  .el-menu-item.is-active {
    background-color: #20409b !important;
  }
  .el-menu-item.is-active {
    color: white !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #e4e7ec;
  }
  .fliped {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .el-menu-item-group__title {
    padding: 0;
    height: 0;
  }
  .cs-lh {
    line-height: 28px !important;
  }
}
</style>
