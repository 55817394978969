<template>
  <div class="text-black w-full">
    <div class="w-full">
      <h2
        class="fs-30 text-center"
        style="
              padding-top: 9pt;
              padding-left: 26pt;
              text-indent: -21pt;
              text-align: left;
            "
      >Lịch sử hình thành sức khoẻ bệnh tật</h2>
      <div id="l6">
        <p class="font-bold fs-20" style="text-align: left">2.4. Các yếu tố nguy cơ sức khoẻ:</p>

        <div>
          <p class="font-bold fs-18" style="text-align: left">2.4.1. Dinh dưỡng:</p>

          <table class="w-full" style="border-collapse: collapse;" cellspacing="0">
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 199pt;">
                <p
                  class="ml-2"
                  style="text-indent: 0pt; line-height: 270%; text-align: left"
                >Lần tẩy giun gần nhất khi nào?</p>
              </td>
              <td class="cs-border" style=" width: 285pt;">
                <br />
              </td>
            </tr>
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 199pt;">
                <p class="ml-2">Có tẩy giun định kỳ không?</p>
              </td>
              <td class="cs-border" style=" width: 285pt;">
                <div class="flex items-center px-4 justify-between">
                  <div class="flex items-center gap-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Có</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Không</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 199pt;">
                <p class="ml-2">Có hay ăn đồ sống, tiết canh không?</p>
              </td>
              <td class="cs-border" style=" width: 285pt;">
                <div class="flex items-center px-4 justify-between">
                  <div class="flex items-center gap-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Có</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Không</span>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <p
            class="mt-2 text-left"
          >- Chế độ ăn hàng ngày (Thời gian? Khẩu phần? Ăn ngoài hay ở nhà?)</p>
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2 ml-2">
              <input type="checkbox" :disabled="true" />
              <span>Chế độ ăn nhiều muối</span>
            </div>
            <div class="flex items-center gap-2 ml-2">
              <input type="checkbox" :disabled="true" />
              <span>Chế độ ăn nhiều dầu mỡ</span>
            </div>
            <div class="flex items-center gap-2 ml-2">
              <input type="checkbox" :disabled="true" />
              <span>
                Chế độ ăn
                nhiều đường
              </span>
            </div>
            <div class="flex items-center gap-2 ml-2">
              <input type="checkbox" :disabled="true" />
              <span>Khác</span>
            </div>
          </div>

          <table
            class="mt-4 w-full"
            style="border-collapse: collapse; margin-left: 6.75pt"
            cellspacing="0"
          >
            <tr style="height: 32pt">
              <td class="cs-border cs-header" style="width: 128pt;">
                <p class="text-center font-bold text-white">Bữa sáng</p>
              </td>
              <td class="cs-border cs-header" style="width: 128pt;">
                <p class="text-center font-bold text-white">Bữa trưa</p>
              </td>
              <td class="cs-border cs-header" style="width: 128pt;">
                <p class="text-center font-bold text-white">Bữa tối</p>
              </td>
              <td class="cs-border cs-header" style="width: 128pt;">
                <p class="text-center font-bold text-white">Bữa phụ</p>
              </td>
            </tr>
            <tr style="height: 198pt">
              <td class="cs-border" style="width: 128pt;">
                <div class="flex flex-col gap-4 px-2">
                  <div>Thời gian:</div>
                  <div>Khẩu phần:</div>
                  <div>Ăn ngoài hay ở nhà:</div>
                  <div>Thường ăn:</div>
                </div>
              </td>
              <td class="cs-border" style="width: 122pt;">
                <div class="flex flex-col gap-4 px-2">
                  <div>Thời gian:</div>
                  <div>Khẩu phần:</div>
                  <div>Ăn ngoài hay ở nhà:</div>
                  <div>Thường ăn:</div>
                </div>
              </td>
              <td class="cs-border" style="width: 117pt;">
                <div class="flex flex-col gap-4 px-2">
                  <div>Thời gian:</div>
                  <div>Khẩu phần:</div>
                  <div>Ăn ngoài hay ở nhà:</div>
                  <div>Thường ăn:</div>
                </div>
              </td>
              <td class="cs-border" style="width: 117pt;">
                <div class="flex flex-col gap-4 px-2">
                  <div>Thời gian:</div>
                  <div>Khẩu phần:</div>
                  <div>Ăn ngoài hay ở nhà:</div>
                  <div>Thường ăn:</div>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div>
          <p class="font-bold fs-18 mt-4" style="text-align: left">2.4.2. Thể chất</p>

          <table class="w-full" style="border-collapse: collapse;" cellspacing="0">
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 484pt;" colspan="3">
                <div class="flex items-center gap-2 ml-2">
                  <input type="checkbox" :disabled="true" />
                  <span>Lối sống tĩnh lại ít vận động</span>
                </div>
              </td>
            </tr>
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 110pt;" rowspan="4">
                <div class="flex items-center gap-2 ml-2">
                  <input type="checkbox" :disabled="true" />
                  <span>Thể dục thể thao</span>
                </div>
              </td>
              <td class="cs-border" style="width: 149pt;">
                <div class="text-left flex items-center px-2">Loại hình vận động:</div>
              </td>
              <td class="cs-border" style="width: 225pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 83pt">
              <td class="cs-border" style="width: 149pt;">
                <div class="text-left flex items-start px-2">Cường độ vận động?</div>
              </td>
              <td class="cs-border" style="width: 225pt;">
                <div class="flex flex-col gap-4 p-2">
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Cao</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Trung bình</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Thấp</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 149pt;">
                <div class="text-left flex items-center px-2">Tập bao nhiêu phút/ngày?</div>
              </td>
              <td class="cs-border" style="width: 225pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 149pt;">
                <div class="text-left flex items-center px-2">Tập bao nhiêu ngày/tuần?</div>
              </td>
              <td class="cs-border" style="width: 225pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 110pt;">
                <div class="flex items-center gap-2 ml-2">
                  <input type="checkbox" :disabled="true" />
                  <span>Khác</span>
                </div>
              </td>
              <td class="cs-border" style="width: 374pt;" colspan="2">
                <p class="text-left">
                  <br />
                </p>
              </td>
            </tr>
          </table>
          <p class="text-left">
            <br />
          </p>
        </div>
        <div>
          <p class="font-bold fs-18" style="text-align: left">2.4.3. Rượu bia:</p>

          <table class="w-full" style="border-collapse: collapse; " cellspacing="0">
            <tr style="height: 47pt">
              <td class="cs-border" style="width: 60pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
              <td class="cs-border cs-header" style="width: 109pt;">
                <p class="text-center font-bold text-white">Bia</p>
              </td>
              <td class="cs-border cs-header" style="width: 109pt;">
                <p class="text-center font-bold text-white">Rượu vang</p>
              </td>
              <td class="cs-border cs-header" style="width: 109pt;">
                <p class="text-center font-bold text-white">Rượu > 30 độ</p>
              </td>
              <td class="cs-border cs-header" style="width: 109pt;">
                <p class="text-center font-bold text-white">Các chất có cồn khác</p>
              </td>
            </tr>
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 60pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                        padding-top: 7pt;
                        padding-left: 8pt;
                        text-align: left;
                      "
                >Loại</p>
              </td>
              <td class="cs-border" style="width: 109pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 108pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 105pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 102pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 64pt">
              <td class="cs-border" style="width: 60pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                        padding-top: 8pt;
                        padding-left: 8pt;
                        padding-right: 13pt;
                        line-height: 123%;
                        text-align: left;
                      "
                >Tần suất</p>
              </td>
              <td class="cs-border" style="width: 109pt;">
                <div class="flex flex-col gap-4 p-2">
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Không bao giờ</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>≤ 1 lần/tháng</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>2 - 3 lần/tuần</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>2 - 4 lần/tháng</span>
                  </div>
                </div>
              </td>
              <td class="cs-border" style="width: 108pt;">
                <div class="flex flex-col gap-4 p-2">
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Không bao giờ</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>≤ 1 lần/tháng</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>2 - 3 lần/tuần</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>2 - 4 lần/tháng</span>
                  </div>
                </div>
              </td>
              <td class="cs-border" style="width: 105pt;">
                <div class="flex flex-col gap-4 p-2">
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Không bao giờ</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>≤ 1 lần/tháng</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>2 - 3 lần/tuần</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>2 - 4 lần/tháng</span>
                  </div>
                </div>
              </td>
              <td class="cs-border" style="width: 102pt;">
                <div class="flex flex-col gap-4 p-2">
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>Không bao giờ</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>≤ 1 lần/tháng</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>2 - 3 lần/tuần</span>
                  </div>
                  <div class="flex items-center gap-2 ml-2">
                    <input type="checkbox" :disabled="true" />
                    <span>2 - 4 lần/tháng</span>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="mt-4 flex flex-col gap-4">
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
          </div>
        </div>
        <div class="mt-4">
          <p class="font-bold fs-18" style="text-align: left">2.4.4. Thuốc lá, thuốc lào</p>

          <table class="w-full" style="border-collapse: collapse;" cellspacing="0">
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 108pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
              <td class="cs-border cs-header" style="width: 109pt;">
                <p class="text-center font-bold text-white">Số lượng</p>
              </td>
              <td class="cs-border cs-header" style="width: 109pt;">
                <p class="text-center font-bold text-white">Thời gian hút</p>
              </td>
              <td class="cs-border cs-header" style="width: 109pt;">
                <p class="text-center font-bold text-white">Tổng số (bao.năm)</p>
              </td>
            </tr>
            <tr style="height: 47pt">
              <td class="cs-border" style="width: 108pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                        padding-top: 8pt;
                        padding-left: 8pt;
                        text-align: left;
                      "
                >Thuốc lá</p>
              </td>
              <td class="cs-border" style="width: 125pt;">
                <p
                  class="s14"
                  style="
                        padding-top: 8pt;
                        padding-left: 8pt;
                       
                        text-align: left;
                      "
                >
                  .....
                  <span class="s18"></span>điếu/ngày
                </p>
                <p
                  class="s14"
                  style="
                        padding-top: 3pt;
                        padding-left: 8pt;
                        text-align: left;
                      "
                >
                  (........
                  <span class="s18"></span>bao/ngày)
                </p>
              </td>
              <td class="cs-border" style="width: 130pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 32pt">
              <td class="cs-border" style="width: 108pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                        padding-top: 8pt;
                        padding-left: 8pt;
                        text-align: left;
                      "
                >Thuốc lá điện tử</p>
              </td>
              <td class="cs-border" style="width: 125pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 130pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 47pt">
              <td class="cs-border" style="width: 108pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                        padding-top: 8pt;
                        padding-left: 8pt;
                        text-align: left;
                      "
                >Thuốc lào</p>
              </td>
              <td class="cs-border" style="width: 125pt;">
                <p
                  class="s14"
                  style="
                        padding-top: 8pt;
                        padding-left: 8pt;
                       
                        text-align: left;
                      "
                >
                  .....
                  <span class="s18"></span>điếu/ngày
                </p>
                <p
                  class="s14"
                  style="
                        padding-top: 3pt;
                        padding-left: 8pt;
                        text-align: left;
                      "
                >
                  (........
                  <span class="s18"></span>bao/ngày)
                </p>
              </td>
              <td class="cs-border" style="width: 130pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p class="text-left">
                  <br />
                </p>
              </td>
            </tr>
          </table>
          <p class="text-left">
            <br />
          </p>
          <div class="s16 flex justify-center" style="text-align: center">
            (Nếu đang hút thuốc, anh/chị có ý định cai thuốc không?
            <div class="flex items-center pl-4 justify-between">
              <div class="flex items-center gap-2">
                <input type="checkbox" :disabled="true" />
                <span>Có</span>
              </div>
              <div class="flex items-center gap-2 ml-2">
                <input type="checkbox" :disabled="true" />
                <span>Không</span>
              </div>
            </div>)
          </div>
          <p class="text-left">
            <br />
          </p>
        </div>
        <div>
          <p class="font-bold fs-18" style="text-align: left">2.4.5. Chất kích thích khác</p>
          <div class="mt-4 flex flex-col gap-4">
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
          </div>
        </div>
        <div class="mt-4">
          <p
            class="font-bold fs-18"
            style="text-align: left"
          >2.4.6. Yếu tố khác(sức khoẻ tinh thần,...)</p>
          <div class="mt-4 flex flex-col gap-4">
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
            <div class="content-none"></div>
          </div>
        </div>

        <div class="mt-4">
          <p class="font-bold fs-18" style="text-align: left">2.4.7. Các vấn đề sức khỏe, bệnh tật</p>

          <div id="l15">
            <div>
              <p class="font-bold fs-18 ml-4" style="text-align: left">2.4.7.1. Bệnh mạn tính</p>

              <table class="w-full" style="border-collapse: collapse;" cellspacing="0">
                <tr style="height: 32pt">
                  <td class="cs-border cs-header" style="width: 128pt;">
                    <p class="text-center font-bold text-white">Bệnh</p>
                  </td>
                  <td class="cs-border cs-header" style="width: 128pt;">
                    <p class="text-center font-bold text-white">Thời gian</p>
                  </td>
                  <td class="cs-border cs-header" style="width: 128pt;">
                    <p class="text-center font-bold text-white">Triệu chứng</p>
                  </td>
                  <td class="cs-border cs-header" style="width: 128pt;">
                    <p class="text-center font-bold text-white">Xử trí</p>
                  </td>
                  <td class="cs-border cs-header" style="width: 128pt;">
                    <p class="text-center font-bold text-white">Di chứng</p>
                  </td>
                </tr>
                <tr style="height: 631pt">
                  <td class="cs-border" style="width: 97pt;">
                    <p class="text-left">
                      <br />
                    </p>
                  </td>
                  <td class="cs-border" style="width: 97pt;">
                    <p class="text-left">
                      <br />
                    </p>
                  </td>
                  <td class="cs-border" style="width: 96pt;">
                    <p class="text-left">
                      <br />
                    </p>
                  </td>
                  <td class="cs-border" style="width: 97pt;">
                    <p class="text-left">
                      <br />
                    </p>
                  </td>
                  <td class="cs-border" style="width: 97pt;">
                    <p class="text-left">
                      <br />
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <div class="mt-4">
              <p class="font-bold fs-18 ml-4" style="text-align: left">2.4.7.2. Bệnh cấp tính</p>

              <table class="w-full" style="border-collapse: collapse;" cellspacing="0">
                <tr style="height: 32pt">
                  <td class="cs-border cs-header" style="width: 128pt;">
                    <p class="text-center font-bold text-white">Bệnh</p>
                  </td>
                  <td class="cs-border cs-header" style="width: 128pt;">
                    <p class="text-center font-bold text-white">Thời gian</p>
                  </td>
                  <td class="cs-border cs-header" style="width: 128pt;">
                    <p class="text-center font-bold text-white">Triệu chứng</p>
                  </td>
                  <td class="cs-border cs-header" style="width: 128pt;">
                    <p class="text-center font-bold text-white">Xử trí</p>
                  </td>
                </tr>
                <tr style="height: 645pt">
                  <td class="cs-border" style="width: 121pt;">
                    <p class="text-left">
                      <br />
                    </p>
                  </td>
                  <td class="cs-border" style="width: 121pt;">
                    <p class="text-left">
                      <br />
                    </p>
                  </td>
                  <td class="cs-border" style="width: 121pt;">
                    <p class="text-left">
                      <br />
                    </p>
                  </td>
                  <td class="cs-border" style="width: 121pt;">
                    <p class="text-left">
                      <br />
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <div class="mt-4">
              <p
                class="font-bold fs-18"
                style="text-align: left"
              >2.4.7.3. Vấn đề sức khỏe bệnh tật khác</p>
              <div class="mt-4 flex flex-col gap-4">
                <div v-for="item in Array(24)" :key="item" class="content-none"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <p class="font-bold fs-18" style="text-align: left">2.4.8. Lịch sử cận lâm sàn</p>
          <table class="w-full" style="border-collapse: collapse;" cellspacing="0">
            <tr style="height: 26pt">
              <td class="cs-border cs-header" style="width: 123pt;" colspan="2">
                <p class="text-center font-bold text-white">Thời điểm</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border cs-header" style="width: 52pt;" rowspan="10">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
                <p class="text-center font-bold text-white">Nước tiểu</p>
              </td>
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >pH</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Tỷ trọng</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Glucose</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >HC</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >BC</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >NIT</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >LEU</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Protein</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Ketone</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Urobilinogen</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border cs-header" style="width: 52pt;" rowspan="10">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
                <p class="text-center font-bold text-white">Công thức máu</p>
              </td>
              <td class="cs-border" style="width: 71pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Hb</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >HCT</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >MCV</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >MCH</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >BC</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >NEU</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >LYM</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >MONO</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >TC</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border cs-header" style="width: 52pt;" rowspan="5">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Glucose</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Albumin</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >CRP</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >TSH</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 24pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >FT4</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border cs-header" style="width: 52pt;" rowspan="13">
                <p class="text-center font-bold text-white">Sinh hóa máu</p>
              </td>
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >HDL-C</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >LDL-C</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Triglycerid</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Cholesterol</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Acid uric</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >GGT</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >ALT</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >AST</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Creatitin</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Ure</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >HbA1C</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Anti HCV</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >HbsAg</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border cs-header" style="width: 52pt;" rowspan="4">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
                <p class="text-center font-bold text-white">Điện giải đồ</p>
              </td>
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Na+</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >K+</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Cl-</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border" style="width: 71pt;">
                <p
                  class="s21"
                  style="
              padding-top: 6pt;
              padding-left: 7pt;
              text-indent: 0pt;
              text-align: left;
            "
                >Ca2+</p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 38pt">
              <td class="cs-border cs-header" style="width: 52pt;">
                <p class="text-center font-bold text-white">Nhóm máu</p>
              </td>
              <td class="cs-border" style="width: 71pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 26pt">
              <td class="cs-border cs-header" style="width: 52pt;">
                <p class="text-center font-bold text-white">Rh</p>
              </td>
              <td class="cs-border" style="width: 71pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 63pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 74pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 78pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 76pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 70pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
          </table>
          <p style="text-indent: 0pt; text-align: left">
            <br />
          </p>
          <table class="w-full" style="border-collapse: collapse; " cellspacing="0">
            <tr style="height: 29pt">
              <td class="cs-border cs-header" style="width: 128pt;">
                <p class="text-center font-bold text-white">Xét nghiệm</p>
              </td>
              <td class="cs-border cs-header" style="width: 128pt;">
                <p class="text-center font-bold text-white">Cơ quan bộ phận</p>
              </td>
              <td class="cs-border cs-header" style="width: 128pt;">
                <p class="text-center font-bold text-white">Thời gian</p>
              </td>
              <td class="cs-border cs-header" style="width: 128pt;">
                <p class="text-center font-bold text-white">Kết quả</p>
              </td>
            </tr>
            <tr style="height: 29pt">
              <td class="cs-border" style="width: 121pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                    padding-top: 6pt;
                    padding-left: 7pt;
                    text-indent: 0pt;
                    text-align: left;
                    "
                >Siêu âm</p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 29pt">
              <td class="cs-border" style="width: 121pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                    padding-top: 6pt;
                    padding-left: 7pt;
                    text-indent: 0pt;
                    text-align: left;
                    "
                >Nội soi</p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 29pt">
              <td class="cs-border" style="width: 121pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                    padding-top: 6pt;
                    padding-left: 7pt;
                    text-indent: 0pt;
                    text-align: left;
                    "
                >X Quang</p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 29pt">
              <td class="cs-border" style="width: 121pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                    padding-top: 6pt;
                    padding-left: 7pt;
                    text-indent: 0pt;
                    text-align: left;
                    "
                >Cắt lớp vi tính</p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
            <tr style="height: 29pt">
              <td class="cs-border" style="width: 121pt;">
                <p
                  class="s12 font-bold fs-17"
                  style="
                    padding-top: 6pt;
                    padding-left: 7pt;
                    text-indent: 0pt;
                    text-align: left;
                    "
                >Khác</p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
              <td class="cs-border" style="width: 121pt;">
                <p style="text-indent: 0pt; text-align: left">
                  <br />
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HealthRiskFactors'
}
</script>

<style lang="scss" scoped>
.cs-border {
  border-top-style: solid;
  border-top-width: 2pt;
  border-top-color: #dadada;
  border-left-style: solid;
  border-left-width: 2pt;
  border-left-color: #dadada;
  border-bottom-style: solid;
  border-bottom-width: 2pt;
  border-bottom-color: #dadada;
  border-right-style: solid;
  border-right-width: 2pt;
  border-right-color: #dadada;
}

.cs-header {
  background-color: #616b80;
  font-size: 16px;

  p {
    margin-bottom: 0 !important;
  }
}

.content-none {
  width: 100%;
  border-bottom: 2px dotted;
}
</style>